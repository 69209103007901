<template>
    <common-form
            ref="form"
            url="web/machine/team"
            :edit="edit"
            :items="items"
            @success="$emit('success')">
    </common-form>
</template>
<script>

    export default {
        data() {
            return {
                edit: false,
            }
        },
        computed: {
            items() {
                return [
                    {
                        type: "input",
                        name: "团队名称",
                        key: "name",
                        required: true,
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                this.$refs.form.show(model)
            },

        }
    }
</script>
